import React from "react"
import { Box, Button, Grid, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import loginLogo from "../../images/drawer_ic.svg"
import { COLORS } from "../../utils/theme"
import { navigate } from "gatsby"

const useStyles = makeStyles(theme => ({
  boxStyle: {
    backgroundColor: "white",
    position: "absolute",
    top: "50px",
    left: "50%",
    width: 550,
    height: 385,
    borderRadius: "8px",
    transform: "translate(-50%, -50%)",
    padding: "20px 0px",
    fontFamily: "DM Sans",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  },
  titleLogin: {
    fontSize: "20px",
    fontStyle: "normal",
    color: "#0A3133",
    marginTop: "20px",
    marginBottom: "10px",
  },
  descLogin: {
    fontSize: "14px",
    fontStyle: "normal",
    wordWrap: "normal",
    color: "#0A3133",
    textAlign: "center",
  },
  spanText: {
    color: COLORS.green,
    fontSize: "14px",
    fontWeight: "bold",
  },
  modalBottom: {
    color: "#000000",
    background: "#F4F5F7",
    width: "100%",
    padding: "15px",
    bottom: "0",
    height: "60px",
    position: "absolute",
  },
  loginBtn: {
    background: `${COLORS.lightGray}`,
    color: COLORS.offBlack,
    marginTop: "50px",
    width: "100%",
    height: "43px",
    textTransform: "none",
    fontWeight: "bold",
    borderRadius: "6px",
    "&:hover": {
      background: `${COLORS.lightGray}`,
    },
  },
  createAccBtn: {
    background: `${COLORS.green}`,
    color: "white",
    marginTop: "20px",
    height: "43px",
    fontWeight: "bold",
    width: "100%",
    textTransform: "none",
    borderRadius: "6px",
    "&:hover": {
      background: `${COLORS.green}`,
    },
  },
  bottomSignup: {
    fontFamily: "DM Sans",
    color: "black",
    opacity: "0.5",
    fontSize: "12px",
  },
}))

function LoginModal() {
  const classes = useStyles()

  return (
    <div>
      <Box hideBackdrop={true} className={classes.boxStyle}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ padding: "0px 20px" }}
        >
          <Grid
            item
            justifyContent="center"
            alignItems="center"
            container
            direction="column"
          >
            <img src={loginLogo} alt="" />

            <Typography className={classes.titleLogin}>
              Racqy for venues
            </Typography>
          </Grid>
          <Grid item justifyContent="center" alignItems="center" container>
            <Typography
              className={classes.descLogin}
              variant="p"
              noWrap={false}
            >
              Do you want your facility to be visible to{" "}
              <span className={classes.spanText}>thousands of people</span> on
              our platform? Create a profile for your facility below!
            </Typography>
          </Grid>
          <Grid justifyContent="center" alignItems="center" container>
            <Button
              disableElevation
              disableTouchRipple
              disableRipple
              variant="contained"
              onClick={() => navigate("/venue/login/")}
              className={classes.loginBtn}
            >
              Log in
            </Button>
            <Button
              disableElevation
              disableTouchRipple
              disableRipple
              variant="contained"
              onClick={() => navigate("/venue/welcome/")}
              className={classes.createAccBtn}
            >
              Create account
            </Button>
          </Grid>
        </Grid>

        <Grid
          justifyContent="center"
          alignItems="center"
          container
          className={classes.modalBottom}
        >
          <Typography className={classes.bottomSignup}>
            Want to log in as a normal user? Log in{" "}
            <span
              onClick={() => navigate("/venue/login/")}
              style={{
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              here
            </span>
          </Typography>
        </Grid>
      </Box>
    </div>
  )
}

export default LoginModal
