import React from "react"
import LoginModal from "./LoginModal"
import { makeStyles } from "@material-ui/core/styles"
import HomeContent from "./HomeContent"
import "./home.css"
import Head from "./Head"

const useStyles = makeStyles(theme => ({
  dialog_like: {
    backgroundColor: "red",
    width: "600px",
    fontFamily: "DM Sans",
    borderRadius: "6px",
    boxShadow: "2px 2px 10px 0px black",
    zIndex: 2,
    position: "absolute",
    marginTop: "42vh",
  },
}))

function Home() {
  const classes = useStyles()

  return (
    <>
      <Head />
      <div style={{ width: "100vw" }}>
        <div className="background-anim">
          <div id="img-1" />
          <div id="img-2" />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div className={classes.dialog_like}>
            <LoginModal />
          </div>
        </div>
      </div>
      <div style={{ height: "75vh" }} />
      <div style={{ width: "100%" }}>
        <HomeContent />
      </div>
    </>
  )
}

export default Home
